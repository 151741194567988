import React from "react";
import { urlFor } from "../../utils/URLBuilder";

export default function StepsSection(props) {
  const { title = "", items = [] } = props;

  return (
    <section className="text-white bg-blue-1D relative py-28">
      <img
        alt=""
        role="none"
        className="absolute top-0 left-[25%]"
        src={require("./blocks1.svg").default}
      />
      <img
        alt=""
        role="none"
        className="absolute left-0 top-[55%] hidden md:block"
        src={require("./blocks3.svg").default}
      />

      <img
        alt=""
        role="none"
        className="absolute right-0 top-[15%] hidden lg:block"
        src={require("./blocks4.svg").default}
      />

      <div className="container mx-auto relative">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center  py-14">
          <div className="">
            <div className="  space-y-6">
              <div className="w-12 h-2 rounded-full bg-blue-67"></div>
              <h1 className="text-3xl md:text-5xl font-bold md:mr-6 pb-6">{title}</h1>
            </div>
          </div>
          <div className="space-y-10 ">
            {items.map((v, i) => (
              <Item key={i} icon={urlFor(v.icon).url()} title={v.title}>
                {v.description}
              </Item>
            ))}
          </div>
        </div>
      </div>

      <img
        role="none"
        alt=""
        className="absolute bottom-0 left-[25%]"
        src={require("./blocks2.svg").default}
      />
    </section>
  );
}

function Item({ title, children, icon }) {
  return (
    <div className="space-y-3">
      <div>
        <img alt="" role="none" src={icon} />
      </div>
      <div className="font-bold text-2xl">{title}</div>
      <div className="max-w-sm">{children}</div>
    </div>
  );
}
