import React from "react";
import RichText from "../RichContent/RichText";

export default function RiskSection(props) {
  const { title = "Reduzimos riscos com tecnologia.", text } = props;
  return (
    <section className="text-dark py-28 relative">
      <img
        role="none"
        alt=""
        className="absolute top-0 left-[30%]"
        src={require("./blocks1.svg").default}
      />

      <div className="container mx-auto relative">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center  ">
          <div className="flex items-center">
            <div className="  space-y-6">
              <div className="w-12 h-2 bg-gradient-to-r rounded-full from-blue-1D to-blue-67"></div>
              <h1 className="text-3xl md:text-5xl font-bold md:mr-6  ">{title}</h1>
            </div>
          </div>
          <div>{text && <RichText color="black" content={text} />}</div>
        </div>
      </div>

      <img
        role="none"
        alt=""
        className="absolute bottom-0 left-[14%]"
        src={require("./blocks2.svg").default}
      />
    </section>
  );
}
