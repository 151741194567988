import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || "qiouyqhp",
  dataset: process.env.GATSBY_SANITY_DATASET || "production"
});

export function urlFor(source) {
  return builder.image(source);
}
