import React from "react";

export default function Header() {
  return (
    <header className="">
      <div className="container   mx-auto">
        <div className="relative">
          <img
            alt="Logomarca Base Digital"
            className="absolute z-40 left-0 top-16 w-28 h-auto"
            src={require("./logo.png").default}
          />
        </div>
      </div>
    </header>
  );
}
