import React from "react";
import { urlFor } from "../../utils/URLBuilder";

const imgProps = {
  placeholder: "tracedSVG"
};

const data = [
  {
    title: "Logun",
    image: require("./logun.png").default,
    url: "https://logun.com.br"
  },
  {
    title: "No One",
    image: require("./noone.png").default,
    url: "https://noone.is"
  },
  {
    title: "Kyvo",
    image: require("./kyvo.png").default,
    url: "https://kyvo.com.br"
  }
];

export default function PartnersSection(props) {
  const { title = "", description = "", items = [] } = props;
  return (
    <section className="text-white  bg-blue-1D relative py-28">
      <img
        role="none"
        alt=""
        className="absolute left-[60%] bottom-0 hidden lg:block"
        src={require("./blocks1.svg").default}
      />

      <img
        role="none"
        alt=""
        className="absolute right-0 top-[20%] hidden lg:block"
        src={require("./blocks2.svg").default}
      />

      <div className="container mx-auto relative">
        <div className="  space-y-6">
          <div className="w-12 h-2 bg-gradient-to-r rounded-full bg-blue-67"></div>
          <h1 className="text-3xl md:text-5xl font-bold md:mr-6  ">{title}</h1>
          <div className="font-light">{description}</div>

          <div className="flex flex-col md:flex-row items-center justify-center md:justify-start ">
            {items.map((v, i) => {
              return (
                <a key={i} href={v.url} className="mr-0 md:mr-14 mt-14 block" target="_blank">
                  <img className="h-16 w-auto" src={urlFor(v.image).url()} alt={v.title} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
