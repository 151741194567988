import React from "react";
import { FiMail } from "@react-icons/all-files/fi/FiMail";
import { FiPhone } from "@react-icons/all-files/fi/FiPhone";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";

export default function ContactSection(props) {
  const { title, description, items, linkedin } = props;
  return (
    <section className="text-dark relative py-28 ">
      <img
        role="none"
        alt=""
        className="absolute left-0 top-[30%] hidden md:block"
        src={require("./blocks1.svg").default}
      />

      <img
        role="none"
        alt=""
        className="absolute top-0 left-[80%]"
        src={require("./blocks2.svg").default}
      />

      <div className="container mx-auto relative">
        <div className="space-y-6 text-center">
          <div className="space-y-6">
            <div className="w-12 h-2 inline-block bg-gradient-to-r rounded-full from-blue-1D to-blue-67"></div>
            <h1 className="text-3xl md:text-5xl font-bold md:mr-6">{title}</h1>
          </div>

          <div className="max-w-md mx-auto">{description}</div>

          <h1 className="text-2xl  font-bold text-gray-81">Contatos</h1>

          <div className="flex flex-col md:flex-row  justify-center items-center text-lg">
            {items.map((item, i) => {
              return (
                <a key={i} className="p-4 flex space-x-2 items-center" href={item.url}>
                  {item.type === "email" ? (
                    <FiMail className="text-blue-1D" />
                  ) : (
                    <FiPhone className="text-blue-1D" />
                  )}
                  <span>{item.title}</span>
                </a>
              );
            })}
          </div>

          <a
            target="_blank"
            className="text-blue-1D  text-lg inline-flex p-2 items-center justify-center space-x-2"
            href={linkedin}
          >
            <span>Linkedin</span> <FiExternalLink />
          </a>
        </div>
      </div>
    </section>
  );
}
