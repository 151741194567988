import React from "react";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";

import { StaticImage } from "gatsby-plugin-image";
import RichText from "../RichContent/RichText";
import { urlFor } from "../../utils/URLBuilder";

const imgProps = {
  layout: "constrained",
  width: 420,
  quality: 80,
  aspectRatio: 1,
  placeholder: "tracedSVG"
};

export default function TeamSection(props) {
  console.log(props);
  const { title = "", text = [], items = [] } = props;
  return (
    <section className="text-dark relative ">
      <img
        role="none"
        alt=""
        className="absolute left-0 top-[25%] hidden 2xl:block"
        src={require("./blocks3.svg").default}
      />

      <div className="container mx-auto relative py-28">
        <img
          role="none"
          alt=""
          className="absolute top-0 right-[0%] hidden lg:block"
          src={require("./blocks1.svg").default}
        />

        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 justify-center  py-14">
          <div className="mr-10 md:col-span-5 lg:col-span-5 xl:col-span-6">
            <div className="space-y-6 pb-6">
              <div className="w-12 h-2 bg-gradient-to-r rounded-full from-blue-1D to-blue-67"></div>

              <h1 className="text-4xl md:text-5xl font-bold md:mr-6 pb-6  ">{title}</h1>
              <div>
                <RichText color="dark" content={text} />
              </div>
            </div>
          </div>
          <div
            className={
              "md:col-span-7 lg:col-span-7 xl:col-span-6 " +
              "md:grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 " +
              "flex items-center flex-col md:flex-row md:items-start"
            }
          >
            {items.map((person, i) => {
              // Some people have bold in their last name
              const [NameComp, LastNameComp] = !person.invertBold
                ? [NameBig, NameSmall]
                : [NameSmall, NameBig];

              return (
                <React.Fragment key={i}>
                  {i === 2 && <div className="hidden lg:block" />}
                  <Item
                    alt={person.title + " " + person.subTitle}
                    image={urlFor(person.image)
                      .crop("center")
                      .size(320, 320)
                      .url()}
                    title={person.title}
                    linkedin={person.linkedin}
                    name={
                      <>
                        <NameComp>{person.title}</NameComp>
                        <LastNameComp>{person.subTitle}</LastNameComp>
                      </>
                    }
                  >
                    {person.description}
                  </Item>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <img
        role="none"
        alt=""
        className="absolute right-0 top-[25%] hidden xl:block"
        src={require("./blocks2.svg").default}
      />
    </section>
  );
}

function NameBig({ children }) {
  return <div className="font-bold text-lg leading-tight">{children}</div>;
}
function NameSmall({ children }) {
  return <div className="text-sm leading-tight">{children}</div>;
}

function Item({ children, image, name, linkedin, alt }) {
  return (
    <div className="space-y-3 mb-6 w-60 md:w-auto">
      <div className="">
        <img alt={alt} src={image} />
      </div>
      <div className="">{name}</div>

      <div className="text-gray-81 text-sm">{children}</div>
      <div>
        <a
          rel="noopener  noreferrer"
          target="_blank"
          className="text-blue-1D  text-sm inline-flex   items-center justify-center space-x-2"
          href={linkedin}
        >
          <span>Linkedin</span> <FiExternalLink />
        </a>
      </div>
    </div>
  );
}
