import React from "react";
import PortableText from "react-portable-text";
import RichText from "../RichContent/RichText";

export default function HeroSection(props) {
  const { title = "MVP for Equity.", text } = props;
  return (
    <section className="bg-blue-1D text-white py-28">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
          <div className="flex items-center">
            <div className="mr-20 space-y-6 font-extralight my-20 ">
              {title && <h1 className="text-3xl md:text-6xl font-bold md:pb-8">{title}</h1>}
              {text && <RichText color="light" content={text} />}
            </div>
          </div>
          <div className="hidden md:flex items-center">
            <div className="relative">
              <img
                role="none"
                alt=""
                className="absolute -left-[22%] z-0 -top-[2%] w-[34%]"
                src={require("./blocks1.svg").default}
              />
              <img
                role="none"
                alt=""
                className="absolute z-20 right-[10%] w-[26%] top-[8.5%]"
                src={require("./blocks2.svg").default}
              />
              <img
                role="none"
                alt=""
                className="absolute z-20 bottom-[4%] left-[18%] w-[26%]"
                src={require("./blocks3.svg").default}
              />
              <img
                role="none"
                alt=""
                className="relative z-10"
                src={require("./techboard.svg").default}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-white"></div>
    </section>
  );
}
