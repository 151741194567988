import React from "react";
import ContactSection from "../ContactSection/ContactSection";
import HeroSection from "../HeroSection/HeroSection";
import PartnersSection from "../PartnersSection/PartnersSection";
import RiskSection from "../RiskSection/RiskSection";
import StartupsSection from "../StartupsSection/StartupsSection";
import StepsSection from "../StepsSection/StepsSection";
import TeamSection from "../TeamSection/TeamSection";

const types = {
  heroBlock: data => {
    return <HeroSection {...data} />;
  },
  riskBlock: data => {
    return <RiskSection {...data} />;
  },
  stepsBlock: data => {
    return <StepsSection {...data} />;
  },
  teamBlock: data => {
    return <TeamSection {...data} />;
  },
  startupBlock: data => {
    return <StartupsSection {...data} />;
  },
  partnersBlock: data => {
    return <PartnersSection {...data} />;
  },
  contactBlock: data => {
    return <ContactSection {...data} />;
  },
  notFound: () => {
    return <div>INVALID COMPONENT</div>;
  }
};

export default function RichContent({ data }) {
  return (
    <>
      {data.map((v, i) => {
        const Comp = types[v._type];
        return <Comp key={i} {...v} />;
      })}
    </>
  );
}
