import React, { useRef } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import { urlFor } from "../../utils/URLBuilder";

const settings = {
  dots: true,

  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function StartupsSection(props) {
  const ref = useRef();

  const { title = "", items = [] } = props;
  return (
    <section className="relative bg-white py-28">
      <img
        role="none"
        alt=""
        className="absolute left-0 top-[50%] hidden lg:block"
        src={require("./blocks1.svg").default}
      />

      <img
        role="none"
        alt=""
        className="absolute bottom-0 right-0 hidden lg:block"
        src={require("./blocks2.svg").default}
      />
      <div className="container mx-auto relative">
        <div className="grid grid-cols-1 md:grid-cols-4">
          {/* Text */}
          <div className="space-y-6">
            <div className="w-12 h-2 bg-gradient-to-r rounded-full from-blue-1D to-blue-67"></div>
            <h1 className="text-4xl md:text-3xl lg:text-5xl font-bold md:mr-6 pb-6  ">{title}</h1>
          </div>
          {/* Slider */}
          <div className="md:col-span-3 md:row-span-4">
            <div className="-mx-4">
              <Slider ref={ref} infinite={items.length > 2} {...settings}>
                {items.map((v, i) => (
                  <Item key={i} data={v} />
                ))}
              </Slider>
            </div>
          </div>
          <div>
            <div className="space-x-2 mt-4 flex justify-center sm:block">
              <button
                title="Página anterior"
                onClick={() => {
                  ref.current.slickPrev();
                }}
                className="p-2 bg-blue-1D hover:bg-gray-81"
              >
                <img alt="" role="none" src={require("./arrow-left.svg").default} />
              </button>
              <button
                title="Próxima página"
                onClick={() => {
                  ref.current.slickNext();
                }}
                className="p-2 bg-blue-1D hover:bg-gray-81"
              >
                <img alt="" role="none" src={require("./arrow-right.svg").default} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Item({ data }) {
  return (
    <div className="bg-blue-1D text-white  mx-4 px-4 py-6 space-y-4 rounded-lg    ">
      <div className="border border-white p-2 inline-block rounded-full text-xs font-semibold">
        status: {data.status}
      </div>
      <div className="w-36">
        <img alt={data.title} src={urlFor(data.image).url()} />
      </div>
      <div>{data.description}</div>
    </div>
  );
}
