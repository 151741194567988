import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import ContactSection from "../components/ContactSection/ContactSection";
import { DevScreenDebug } from "../components/DevScreenDebug";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import RichContent from "../components/RichContent/RichContent";
import { Helmet } from "react-helmet";
import { urlFor } from "../utils/URLBuilder";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanitySiteSettings {
        nodes {
          id
          _id
          title

          meta {
            name
            content
          }
          _rawFavicon
          _rawShareImage
        }
      }

      allSanityPage(filter: { _id: { eq: "home" } }) {
        nodes {
          _rawContent(resolveReferences: { maxDepth: 4 })
        }
      }
    }
  `);

  const content = data.allSanityPage.nodes[0]._rawContent;
  const settings = data.allSanitySiteSettings.nodes[0];

  return (
    <main className="bg-gray-F8">
      <Header />
      {process.env.NODE_ENV === "development" && <DevScreenDebug />}

      <Helmet
        htmlAttributes={{
          lang: "pt-br"
        }}
      >
        <title>{settings.title}</title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="lang" content="pt-BR" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="website" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"
        />

        <link rel="shortcut icon" href={urlFor(settings._rawFavicon).url()} />
        <meta property="og:image" content={urlFor(settings._rawShareImage).url()} />

        {settings.meta.map((v, i) => {
          return <meta key={i} name={v.name} property={v.name} content={v.content} />;
        })}
      </Helmet>
      <RichContent data={content} />
      <Footer />
    </main>
  );
};

export default IndexPage;
