import React from "react";

export default function Footer() {
  return (
    <footer className="bg-blue-1D text-white p-4">
      <div className="container mx-auto">
        <div className="flex flex-col-reverse md:flex-row  items-center justify-between">
          <div className="my-1">© BASE TECH VENTURES. TODOS OS DIREITOS RESERVADOS.</div>
          <div className="my-1">
            <a title="Base Digital" target="_blank" href="https://base.digital">
              <img alt="Logomarca Base Digital" src={require("./logo.svg").default} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
