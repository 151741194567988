import PortableText from "react-portable-text";
import React from "react";

export default function RichText({ content, color = "light" }) {
  return (
    <div
      className={
        color === "light"
          ? "prose prose-invert prose-p:text-white "
          : "prose prose-a:font-bold prose-a:text-blue-1D prose-a:no-underline "
      }
    >
      <PortableText content={content} />
    </div>
  );
}
